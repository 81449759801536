import React from "react";

// import { StaticImage } from "gatsby-plugin-image";

const AmenitiesSection2 = ({
  // headingLevel,
  // className,
  sectionTitle,
  // sectionDesc,
  // title1,
  // sectionContentArray,
  arrary1,
}) => {
  return (
    <>
      <section className="mb-20 md:mb-32 lg:mb-16">
        <div className="container">
          <h2>{sectionTitle}</h2>
          <div className="">
            <div className="grid items-center gap-y-4 md:grid-cols-1 ">
              <ul className="grid grid-flow-row auto-rows-max grid-cols-1 gap-y-3 sm:grid-cols-2 md:gap-y-[5px] md:gap-x-10 lg:gap-x-20">
                {arrary1 &&
                  arrary1.map((content) => (
                    <li className="flex py-[5px]">
                      <svg
                        class="mr-2 h-6 w-6 flex-none fill-primary-400 stroke-secondary-400 stroke-2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <circle cx="12" cy="12" r="11" />
                        <path
                          d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9"
                          fill="none"
                        />
                      </svg>
                      <p className="flex-initial">{content}</p>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AmenitiesSection2;
